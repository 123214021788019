import {
  Bank,
  Buildings,
  Calculator,
  Chalkboard,
  ChalkboardTeacher,
  Envelope,
  House,
  IconContext,
  Presentation,
  Question,
  Receipt,
  Student,
  UsersFour,
} from "phosphor-react";
import React from "react";
import logo from "../../assets/images/naptin-logo.svg";
import SideBarItem from "./SideBarItem";
import SideBarMenu from "./SideBarMenu";
import { useAuth } from "../../providers/AuthProvider";

const SideBar = ({ show = false }) => {
  const { hasPermission } = useAuth();
  const items = [
    {
      name: "General Dashboard",
      icon: <House className="3xl:w-8 3xl:h-8" />,
      menu: false,
      link: "/admin/dashboard",
      permission: "any",
      items: [],
    },
    {
      name: "Trainee Dashboard",
      icon: <Student className="3xl:w-8 3xl:h-8" />,
      menu: true,
      link: "#",
      permission: "trainee management",
      items: [
        { name: "Dashboard", link: "/admin/manage/trainee/dashboard" },
        {
          name: "Prospective Trainees",
          link: "/admin/manage/trainee/prospective",
        },
        {
          name: "Shortlisted Trainees",
          link: "/admin/manage/trainee/shortlisted",
        },
        { name: "Confirmed Trainees", link: "/admin/manage/trainee/confirmed" },
        { name: "Alumni Records", link: "/admin/manage/trainee/alumni" },
      ],
    },
    {
      name: "Trainer Dashboard",
      icon: <ChalkboardTeacher className="3xl:w-8 3xl:h-8" />,
      menu: true,
      link: "#",
      permission: "trainer management",
      items: [
        { name: "Dashboard", link: "/admin/manage/trainer/dashboard" },
        {
          name: "Confirmed Supervisors",
          link: "/admin/manage/trainer/supervisors/confirmed",
        },
        {
          name: "Prospective Supervisors",
          link: "/admin/manage/trainer/supervisors/prospectives",
        },
      ],
    },
    {
      name: "Training Management",
      icon: <Chalkboard className="3xl:w-8 3xl:h-8" />,
      menu: true,
      link: "#",
      permission: "training program management",
      items: [
        { name: "Advertised Courses", link: "/admin/manage/training/adverts" },
        // {
        //   name: "Exam Definitions",
        //   link: "/admin/manage/training/configure/exams",
        // },
        // {
        //   name: "Admission Letter Templates",
        //   link: "/admin/manage/training/admissions/templates",
        // },
        { name: "Courses", link: "/admin/manage/training/courses" },
        { name: "Programs", link: "/admin/manage/training/programs" },
        // {
        //   name: "Training Suggestions",
        //   link: "/admin/manage/training/suggestions",
        // },
      ],
    },
    {
      name: "Sponsor Management",
      icon: <Buildings className="3xl:w-8 3xl:h-8" />,
      menu: true,
      link: "#",
      permission: "trainee company management",
      items: [
        {
          name: "Manage Sponsors",
          link: "/admin/manage/sponsor/companies",
        },
        {
          name: "Group Training Applications",
          link: "/admin/manage/sponsor/group-trainings",
        },
      ],
    },
    {
      name: "Training Center Management",
      icon: <Presentation className="3xl:w-8 3xl:h-8" />,
      menu: true,
      link: "#",
      permission: "training center management",
      items: [
        { name: "Manage RTCs", link: "/admin/manage/rtc/all" },
        // { name: "RTC Users", link: "/admin/manage/rtc/users" },
        {
          name: "Course Assessment & Grading",
          link: "/admin/manage/rtc/course/assessments",
        },
        {
          name: "Program Assessment & Grading",
          link: "/admin/manage/rtc/program/assessments",
        },
        // { name: "Field Locations", link: "/admin/manage/rtc/locations" },
        // { name: "Trainee Posting", link: "/admin/manage/rtc/postings" },
      ],
    },
    {
      name: "Institution",
      icon: <Bank className="3xl:w-8 3xl:h-8" />,
      menu: true,
      link: "#",
      permission: "training center management",
      items: [
        {
          name: "Announcements",
          link: "/admin/manage/institution/announcements",
        },
        { name: "Calendar", link: "/admin/manage/institution/calendar" },
        // { name: "Forums", link: "/admin/manage/institution/forums" },
      ],
    },
    {
      name: "Finance",
      icon: <Calculator className="3xl:w-8 3xl:h-8" />,
      menu: true,
      link: "#",
      permission: "finance management",
      items: [
        // { name: "Payment Types", link: "/admin/manage/payment/types" },
        { name: "Payment Records", link: "/admin/manage/payment/records" },
      ],
    },
    {
      name: "User Management",
      icon: <UsersFour className="3xl:w-8 3xl:h-8" />,
      menu: true,
      link: "#",
      permission: "super",
      items: [
        { name: "Admin Users", link: "/admin/manage/users/admins" },
        { name: "System Users", link: "/admin/manage/users/system" },
        { name: "Metrics & Analytics", link: "/admin/manage/users/stats" },
      ],
    },
    {
      name: "Support",
      icon: <Question className="3xl:w-8 3xl:h-8" />,
      menu: true,
      link: "#",
      permission: "support and helpdesk management",
      items: [
        { name: "Dashboard", link: "/admin/manage/support/dashboard" },
        { name: "Tickets & Requests", link: "/admin/manage/support/tickets" },
        {
          name: "FAQs",
          link: "/admin/manage/support/faqs",
        },
        // { name: "Payment Support", link: "/admin/manage/support/payment" },
        // {
        //   name: "Testimonials",
        //   link: "/admin/manage/support/testimonials",
        // },
      ],
    },
    {
      name: "Messaging",
      icon: <Envelope className="3xl:w-8 3xl:h-8" />,
      menu: true,
      link: "#",
      permission: "messaging management",
      items: [
        { name: "Email Console", link: "/admin/manage/messaging/emails" },
        { name: "SMS Console", link: "/admin/manage/messaging/smses" },
      ],
    },
    {
      name: "Reports",
      icon: <Receipt className="" />,
      menu: true,
      link: "#",
      permission: "report management",
      items: [
        { name: "Trainee Reports", link: "/admin/manage/trainee/reports" },
        {
          name: "Trainer Reports",
          link: "/admin/manage/trainer/supervisors/reports",
        },
        // { name: "Training Reports", link: "/admin/manage/training/reports" },
        { name: "Sponsor Reports", link: "/admin/manage/sponsor/reports" },
        { name: "RTC Reports", link: "/admin/manage/rtc/reports" },
      ],
    },
  ];
  return (
    <aside
      className={`${
        show ? "flex" : "hidden"
      } xl:flex flex-col z-10 bg-white max-h-screen xl:max-h-full min-h-screen max-w-sm xl:max-w-full min-w-[15rem]`}
    >
      {show ? (
        <></>
      ) : (
        <a href="/admin/dashboard" className="mt-10 ml-5">
          <img src={logo} alt="Naptin Logo" />
        </a>
      )}

      <IconContext.Provider value={{ size: "1.5rem", weight: "duotone" }}>
        <ul className={`${show ? "mt-4" : "mt-12"}`}>
          {items.map(({ name, icon, menu, link, permission, items }, index) =>
            hasPermission(permission) ? (
              menu ? (
                <SideBarMenu
                  name={name}
                  icon={icon}
                  items={items}
                  key={index}
                />
              ) : (
                <SideBarItem name={name} icon={icon} link={link} key={index} />
              )
            ) : (
              <></>
            )
          )}
        </ul>
      </IconContext.Provider>
    </aside>
  );
};

export default SideBar;
