import React, { useEffect, useState } from "react";
// import Select from "react-select";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";

const CourseAssessmentAndGrading = () => {
  const {
    data: { courseResults },
    loaders: { getCourseResults },
  } = useData();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      getCourseResults();
      setLoaded(true);
    }
  }, [loaded, getCourseResults]);

  return (
    <PageBuilder name="Course Assessment & Grading">
      <div className="flex flex-col w-full p-4">
        {/* <div className="flex flex-col">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
            Filter Assessments
          </span>
          <div className="flex w-full">
            <div className="flex flex-col mr-2">
              <span className="text-black">Exam Definition</span>
              <Select
                options={[]}
                isSearchable={false}
                className="flex items-center justify-center min-w-[15rem]"
                classNamePrefix="select"
                placeholder="All"
              />
            </div>
            <div className="flex flex-col mr-2">
              <span className="text-black">Assessment Status</span>
              <Select
                options={[]}
                isSearchable={false}
                className="flex items-center justify-center min-w-[15rem]"
                classNamePrefix="select"
                placeholder="Awaiting Assessment"
              />
            </div>
            <div className="flex flex-col">
              <button type="submit" className="mx-0 my-4 btn btn-primary">
                Apply
              </button>
            </div>
          </div>
        </div> */}
        <PaginatedDataTable
          title="List of Trainees Grades"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            {
              Header: "Trainee Name",
              accessor: (row) => {
                return `${row.firstName} ${row.surName}`;
              },
            },
            { Header: "Trainee Email", accessor: "email" },
            { Header: "Course Title", accessor: "courseTitle" },
            { Header: "Assignment Score", accessor: "assignmentScore" },
            { Header: "Quiz Score", accessor: "quizScore" },
            { Header: "Exam Score", accessor: "examScore" },
            { Header: "Grade", accessor: "grade" },
          ]}
          data={courseResults?.data}
          count={courseResults?.count}
        />
      </div>
    </PageBuilder>
  );
};

export default CourseAssessmentAndGrading;
