import React, { useEffect, useState } from "react";
// import Select from "react-select";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { format } from "date-fns";

const ProgramAssessmentAndGrading = () => {
  const {
    data: { programResults },
    loaders: { getProgramResults },
  } = useData();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      getProgramResults();
      setLoaded(true);
    }
  }, [loaded, getProgramResults]);

  console.log(programResults);

  return (
    <PageBuilder name="Program Assessment & Grading">
      <div className="flex flex-col w-full p-4">
        {/* <div className="flex flex-col">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
            Filter Assessments
          </span>
          <div className="flex w-full">
            <div className="flex flex-col mr-2">
              <span className="text-black">Exam Definition</span>
              <Select
                options={[]}
                isSearchable={false}
                className="flex items-center justify-center min-w-[15rem]"
                classNamePrefix="select"
                placeholder="All"
              />
            </div>
            <div className="flex flex-col mr-2">
              <span className="text-black">Assessment Status</span>
              <Select
                options={[]}
                isSearchable={false}
                className="flex items-center justify-center min-w-[15rem]"
                classNamePrefix="select"
                placeholder="Awaiting Assessment"
              />
            </div>
            <div className="flex flex-col">
              <button type="submit" className="mx-0 my-4 btn btn-primary">
                Apply
              </button>
            </div>
          </div>
        </div> */}
        <PaginatedDataTable
          title="List of Trainees Grades"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            {
              Header: "Trainee Email",
              accessor: "trainee_email",
            },
            { Header: "Course Code", accessor: "course_code" },
            { Header: "Course Title", accessor: "course_title" },
            { Header: "Program Name", accessor: "program_name" },
            { Header: "Obtainable Score", accessor: "obtainable_score" },
            { Header: "First Exam Score", accessor: "exam_1" },
            { Header: "Second Exam Score", accessor: "exam_2" },
            { Header: "Overall Score", accessor: "Score" },
            {
              Header: "Session Start Date",
              accessor: (row) => {
                return format(new Date(row.calendar_start_date), "dd/MM/yyyy");
              },
            },
            {
              Header: "Session End Date",
              accessor: (row) => {
                return format(new Date(row.calendar_end_date), "dd/MM/yyyy");
              },
            },
          ]}
          data={programResults?.data}
          count={programResults?.count}
        />
      </div>
    </PageBuilder>
  );
};

export default ProgramAssessmentAndGrading;
