import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDetails, reset } from "../../../features/details/detailsSlice";
import { Outlet, Link, useNavigate } from "react-router-dom";
import ErrorMessages from "../../utilities/ErrorMessages";
import Images from "../../utilities/Images";
import "./layout.css";
import urls from "../../utilities/urls";

import { ImHome } from "react-icons/im";

import {
  Button,
  Container,
  Nav,
  Alert,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";

const TraineeHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { details, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.details
  );

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [dp, setDp] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [nav, setNav] = useState(false);
  useEffect(() => {
    if (isError) {
      console.log(message);
    }
    if (isSuccess) {
      setDp(details.data.company.logo);
      setFirstName(details.data.company.name);
      // if (details.data.user.roles[0] == "trainer") {
      //   navigate("/trainer");
      // }
      console.log(details);
    } else if (!details) {
      window.location.href = urls.home;
    }
    console.log(details);
    dispatch(getDetails());
    return () => {
      reset();
    };
  }, [dispatch, isSuccess]);

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          console.log(json, token);
          //alert(json.message)
        } else if (json.success === true) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else if (json.message !== null) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else {
          // alert(ErrorMessages.UnkownRetry);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };
  const changeNav = () => {
    if (nav) setNav(false);
    setNav(true);
  };
  return (
    <>
      <div class="header bg-white flex justify-between items-center">
        <div className="desk-nav container-fluid">
          <div className="float-left">
            <a href="javascript:void(0);" id="toggle_btn">
              <i class="fas fa-align-left text-dark"></i>
            </a>
          </div>
          <div className="float-right">
            <ul class="nav user-menu desk-">
              <li class="adj-left nav-item dropdown mt-2">
                <a
                  href="javascript:void(0)"
                  class="dropdown-toggle user-link  nav-link"
                  data-toggle="dropdown"
                >
                  <span class="user-img">
                    <img
                      class="rounded-circle"
                      src={dp == null ? Images.avatar : dp}
                      width="40"
                      alt="User"
                    />
                  </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <span class="dropdown-item" to="/sponsor/personal_profile">
                    Hi, {firstName}
                  </span>
                  <Link class="dropdown-item" to="/sponsor/personal_profile">
                    Profile
                  </Link>
                  <a onClick={() => logOut()} class="dropdown-item" href="#">
                    Logout{" "}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="mobile_btn">
          <Navbar
            collapseOnSelect
            expand="sm"
            bg="light"
            variant="light"
            fixed="top"
            className="p-3"
          >
            <Container className="d-flex">
              <Navbar.Brand href="#home">
                <div class="pull-left logo-box">
                  <div class="logo">
                    <a href="/">
                      <img src="/assets/images/logo-2.png" alt="" title="" />
                    </a>
                  </div>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                placement="start"
              />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Link to="/sponsor/dashboard">
                    <i class="fas fa-columns float-left"></i>{" "}
                    <span className="text-sm float-left">Dashboard</span>
                  </Link>
                  <Link to="/sponsor/personal_profile" className="my-2">
                    <i class="fas fa-solid fa-user-tie float-left"></i>{" "}
                    <span className="text-sm float-left">Profile</span>
                  </Link>
                </Nav>
                <Link to="/sponsor/available_courses" className="my-3">
                  <i class="fas fa-users float-left"></i>{" "}
                  <span className="text-sm float-left">
                    Group Training Sponsorship
                  </span>
                </Link>
                <br></br>
                <Link to="/sponsor/application" className="my-2">
                  <i class="fas fa-solid fa-paperclip float-left"></i>{" "}
                  <span className="text-sm float-left">Applications</span>
                </Link>
                <br></br>
                <Link to="/sponsor/benefactor" className="my-2">
                  <i class="fas fa-user-friends float-left "></i>{" "}
                  <span className="text-sm float-left"> Benefactors</span>
                </Link>
                <br></br>
                <Link to="/sponsor/payments" className="my-2">
                  <i class="fas fa-money-check float-left"></i>{" "}
                  <span className="text-sm float-left"> Payments</span>
                </Link>
                <br></br>
                <Link to="/sponsor/payments-history" className="my-2">
                  <i class="fas fa-money-check float-left"></i>{" "}
                  <span className="text-sm float-left"> History</span>
                </Link>
                <br></br>
                <Link to="" className="my-2">
                  <i class="fa fa-sign-out float-left"></i>{" "}
                  <span
                    onClick={() => logOut()}
                    class="text-sm float-left"
                    href="#"
                  >
                    Logout{" "}
                  </span>
                </Link>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default TraineeHeader;
