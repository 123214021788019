import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ErrorMessages from "../../utilities/ErrorMessages";
import urls from "../../utilities/urls";
import { ImHome } from "react-icons/im";

const TraineeNavMenu = () => {
  const [dropDown1, setDropDown1] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);

  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          //  alert(json.message)
        } else if (json.success === true) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else if (json.message !== null) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
          alert(ErrorMessages.UnkownRetry);
        }
      })
      .catch((error) => {
        localStorage.removeItem("token");
        window.location.href = urls.home + "logout";
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };

  const clickDropDown = (x) => {
    if (x == 1) {
      if (dropDown1) setDropDown1(false);
      else setDropDown1(true);
    }
    if (x == 2) {
      if (dropDown2) setDropDown2(false);
      else setDropDown2(true);
    }
  };

  return (
    <div class="sidebar mt-20 fixed" id="sidebar">
      {/* <div class="sidebar-logo">
        <a href="https://tmis.naptinportal.com/">
          <img src="assets/img/logo-icon.png" class="img-fluid" alt="" />
        </a>
      </div> */}
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu md:block">
          <ul>
            <li class="submenu">
              <a href={urls.home}>
                <ImHome size={20} />
                <span>Home</span>
              </a>
            </li>
            <li class="active">
              <Link to="/sponsor/dashboard">
                <i class="fas fa-columns"></i> <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/sponsor/personal_profile">
                <i class="fas fa-solid fa-user-tie"></i> <span>Profile</span>
              </Link>
            </li>
            <li>
              <Link to="/sponsor/available_courses">
                <i class="fas fa-users"></i>{" "}
                <span>Group Training Sponsorship</span>
              </Link>
            </li>
            <li>
              <Link to="/sponsor/application">
                <i class="fas fa-solid fa-paperclip"></i>{" "}
                <span>Applications</span>
              </Link>
            </li>
            <li>
              <Link to="/sponsor/benefactor">
                <i class="fas fa-user-friends"></i> <span> Benefactors</span>
              </Link>
            </li>
            <li>
              <Link to="/sponsor/payments">
                <i class="fas fa-money-check"></i> <span> Payments</span>
              </Link>
            </li>
            <li>
              <Link to="/sponsor/payments-history">
                <i class="fas fa-cloud"></i> <span> History</span>
              </Link>
            </li>

            <li
              onClick={() => {
                logOut();
              }}
            >
              <Link to={"#"}>
                <i class="fas fa-power-off"></i> <span> Logout</span>
              </Link>
            </li>
            <li>
              <Link to={""}> </Link>
            </li>
            <li>
              <Link to={""}> </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TraineeNavMenu;
