import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NumberToNaira from "../utilities/numberToNaira";
import urls from "../utilities/urls";
import PageTitle from "./Layouts/PageTitle";
import { toast } from "react-toastify";

const SponsorPayment = () => {
  const [editProfile, setEditProfile] = useState(false);
  const token = localStorage.getItem("token");
  const [payment, setPayment] = useState([]);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const openLink = (x) => {
    window.location = x;
  };
  const postOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
  };
  const paymentHistory = async () => {
    await axios
      .get(urls.api + "payment/history/", postOptions)
      .then((result) => {
        console.log(result.data.data.payments);
        setPayment(result.data.data.payments);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occured");
      });
  };

  useEffect(() => {
    paymentHistory();
  }, []);

  return (
    <div className="content container-fluid">
      <PageTitle title="Payments History" />

      <div class="card">
        <div class="card-body">
          <div style={{ display: !editProfile ? `block` : `none` }}>
            <div className="row">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Applications</th>
                      <th>Status</th>
                      <th>Amount </th>
                      <th>Paid On</th>
                      <th>RRR </th>
                    </tr>
                  </thead>
                  <tbody>
                    {payment.length ? (
                      payment.map((key) => {
                        return (
                          <>
                            <tr>
                              <td>
                                {key.course?.title
                                  ? key.course?.title
                                  : key.program?.name}
                              </td>
                              <td>{key.status}</td>
                              <td>{key.amount}</td>
                              <td>
                                {new Date(key.paidOn).toLocaleDateString(
                                  "en-US",
                                  DATE_OPTIONS
                                )}
                              </td>
                              <td>{key.rrr}</td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorPayment;
