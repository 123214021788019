import { Spinner } from "flowbite-react";
import React, { useState, useContext, createContext } from "react";
import { useClient } from "./AuthProvider";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([{ error: false, message: undefined }]);
  const [trainees, setTrainees] = useState({
    confirmed: {
      count: 0,
      data: [],
      page: 1,
    },
    shortlisted: {
      count: 0,
      data: [],
      page: 1,
    },
    prospective: {
      count: 0,
      data: [],
      page: 1,
    },
  });
  const [trainers, setTrainers] = useState({
    confirmed: {
      count: 0,
      data: [],
      page: 1,
    },
    prospective: {
      count: 0,
      data: [],
      page: 1,
    },
  });
  const [trainingCenters, setTrainingCenters] = useState({
    count: 0,
    data: [],
  });
  const [sponsors, setSponsors] = useState({
    count: 0,
    data: [],
    page: 1,
  });
  const [groupApplications, setGroupApplications] = useState({
    count: 0,
    data: [],
    page: 1,
  });
  const [payments, setPayments] = useState({});
  const [courseResults, setCourseResults] = useState({
    count: 0,
    data: [],
    page: 1,
  });
  const [programResults, setProgramResults] = useState({
    count: 0,
    data: [],
    page: 1,
  });
  const [messagingList, setMessagingList] = useState();
  const [courseMetaData, setCourseMetaData] = useState({
    types: [],
    categories: [],
  });

  const [permissions, setPermissions] = useState([]);

  const [courses, setCourses] = useState({
    count: 0,
    data: [],
    page: 1,
  });
  const [programs, setPrograms] = useState([]);
  const [adminUsers, setAdminUsers] = useState({
    active: {
      count: 0,
      data: [],
      page: 1,
    },
    inactive: {
      count: 0,
      data: [],
      page: 1,
    },
    all: {
      count: 0,
      data: [],
      page: 1,
    },
  });
  const [systemUsers, setSystemUsers] = useState({
    active: {
      count: 0,
      data: [],
      page: 1,
    },
    inactive: {
      count: 0,
      data: [],
      page: 1,
    },
    all: {
      count: 0,
      data: [],
      page: 1,
    },
  });
  const [announcements, setAnnouncements] = useState({
    count: 0,
    data: [],
    page: 1,
  });
  const [stats, setStats] = useState({});
  const [states, setStates] = useState([]);
  const [tickets, setTickets] = useState({
    count: 0,
    data: [],
    page: 1,
  });
  const [faqs, setFAQs] = useState({
    count: 0,
    data: [],
    page: 1,
  });
  const [alumni, setAlumni] = useState({
    count: 0,
    data: [],
    page: 1,
  });

  const client = useClient();

  const getTrainees = async ({
    getConfirmed = false,
    getShortlisted = false,
    getProspective = false,
  }) => {
    const { confirmed, shortlisted, prospective } = trainees;

    setLoading(true);
    if (getConfirmed) {
      client(
        `/api/v1/module/trainee-management/trainees/confirmed?page=${confirmed.page}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          setTrainees({
            ...trainees,
            confirmed: {
              count: res.data.meta.total,
              data: res.data.applicants,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }

    if (getShortlisted) {
      client(
        `/api/v1/module/trainee-management/trainees/shortlisted?page=${shortlisted.page}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          setTrainees({
            ...trainees,
            shortlisted: {
              count: res.data.meta.total,
              data: res.data.applicants,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }

    if (getProspective) {
      client(
        `/api/v1/module/trainee-management/trainees/prospective?page=${prospective.page}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          setTrainees({
            ...trainees,
            prospective: {
              count: res.data.meta.total,
              data: res.data.applicants,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }
  };

  const getTrainers = async ({
    getConfirmed = false,
    getProspective = false,
  }) => {
    const { confirmed, prospective } = trainers;

    setLoading(true);
    if (getConfirmed) {
      // TODO Change this endpoint to the correct one
      client(
        `/api/v1/module/trainer-management/trainers/confirmed?page=${confirmed.page}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          setTrainers({
            ...trainers,
            confirmed: {
              count: res.data.meta.total,
              data: res.data.users,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }

    if (getProspective) {
      // TODO Change this endpoint to the correct one
      client(
        `/api/v1/module/trainer-management/trainers/prospective?page=${prospective.page}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          setTrainers({
            ...trainers,
            prospective: {
              count: res.data.meta.total,
              data: res.data.users,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }
  };

  const getTrainingCenters = async () => {
    setLoading(true);
    client(`/api/v1/regional-training-centers`, {
      method: "GET",
    })
      .then((res) => {
        setTrainingCenters({
          count: res.data.length,
          data: res.data,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getStats = async () => {
    setLoading(true);
    client("/api/v1/stats/dashboard", {
      method: "GET",
    })
      .then((res) => {
        setStats(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getCourseResults = async () => {
    setLoading(true);
    client("/api/v1/results/all", {
      method: "GET",
    })
      .then((res) => {
        setCourseResults({
          count: res.data.meta.total,
          data: res.data.results,
          page: res.data.meta.currentPage,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getProgramResults = async () => {
    setLoading(true);
    client("/api/v1/results/all/program", {
      method: "GET",
    })
      .then((res) => {
        setProgramResults({
          count: res.data.meta.total,
          data: res.data.results,
          page: res.data.meta.currentPage,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getPermissions = async () => {
    setLoading(true);
    client("/api/v1/user/management/admin/permissions/all", {
      method: "GET",
    })
      .then((res) => {
        setPermissions(res.data);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getAdminUsers = async ({
    getAll = false,
    getActive = false,
    getInActive = false,
  }) => {
    const { all, active, inactive } = adminUsers;

    if (getAll) {
      setLoading(true);
      client(`/api/v1/user/management/admin/all?page=${all.page}`, {
        method: "GET",
      })
        .then((res) => {
          setAdminUsers({
            ...adminUsers,
            all: {
              count: res.data.meta.total,
              data: res.data.users,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }

    if (getActive) {
      setLoading(true);
      client(`/api/v1/user/management/admin/all/active?page=${active.page}`, {
        method: "GET",
      })
        .then((res) => {
          setAdminUsers({
            ...adminUsers,
            active: {
              count: res.data.meta.total,
              data: res.data.users,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }

    if (getInActive) {
      setLoading(true);
      client(
        `/api/v1/user/management/admin/all/inactive?page=${inactive.page}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          setAdminUsers({
            ...adminUsers,
            inactive: {
              count: res.data.meta.total,
              data: res.data.users,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }
  };

  const getSystemUsers = async ({
    getAll = false,
    getActive = false,
    getInActive = false,
  }) => {
    const { all, active, inactive } = adminUsers;

    if (getAll) {
      setLoading(true);
      client(`/api/v1/user/management/all-users?page=${all.page}`, {
        method: "GET",
      })
        .then((res) => {
          setSystemUsers({
            ...systemUsers,
            all: {
              count: res.data.meta.total,
              data: res.data.users,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }

    if (getActive) {
      setLoading(true);
      client(`/api/v1/user/management/all-users/active?page=${active.page}`, {
        method: "GET",
      })
        .then((res) => {
          setSystemUsers({
            ...systemUsers,
            active: {
              count: res.data.meta.total,
              data: res.data.users,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }

    if (getInActive) {
      setLoading(true);
      client(
        `/api/v1/user/management/all-users/inactive?page=${inactive.page}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          setSystemUsers({
            ...systemUsers,
            inactive: {
              count: res.data.meta.total,
              data: res.data.users,
              page: res.data.meta.currentPage,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([
            ...errors,
            { error: true, message: error?.response?.data?.message },
          ]);
        });
    }
  };

  const getAnnouncements = async () => {
    const { page } = announcements;

    setLoading(true);
    // TODO Change this endpoint to the correct one
    client(`/api/v1/announcements?page=${page}`, {
      method: "GET",
    })
      .then((res) => {
        setAnnouncements({
          count: res.data.meta.total,
          data: res.data.announcements,
          page: res.data.meta.currentPage,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getPayments = async (status) => {
    const page = payments[status]?.page ?? 1;

    setLoading(true);
    // TODO Change this endpoint to the correct one
    client(
      `/api/v1/payment/${
        status === "all" ? "all" : "all/" + status
      }?page=${page}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        setPayments({
          ...payments,
          [status]: {
            count: res.data.meta.total,
            data: res.data.payments,
            page: res.data.meta.currentPage,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getSponsors = async () => {
    setLoading(true);
    client(`/api/v1/module/company-management/all-companies`, {
      method: "GET",
    })
      .then((res) => {
        setSponsors({
          count: res.data.meta.total,
          data: res.data.users,
          page: res.data.meta.currentPage,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getGroupApplications = async (companyId) => {
    setLoading(true);
    client(
      `/api/v1/module/company-management/all-group-application?page=${groupApplications.page}&companyUuid=${companyId}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        setGroupApplications({
          count: res.data.meta.total,
          data: res.data.benefactors,
          page: res.data.meta.currentPage,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getCourses = async () => {
    setLoading(true);
    client(`/api/v1/courses?page=${courses.page}`, {
      method: "GET",
    })
      .then((res) => {
        setCourses({
          count: res.data.meta.total,
          data: res.data.courses,
          page: res.data.meta.currentPage,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getPrograms = async () => {
    setLoading(true);
    client(`/api/v1/program/all?page=${courses.page}`, {
      method: "GET",
    })
      .then((res) => {
        setPrograms(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getCourseMetaData = async () => {
    setLoading(true);
    client("/api/v1/course-categories", {
      method: "GET",
    })
      .then((res1) => {
        client("/api/v1/course-types", {
          method: "GET",
        })
          .then((res2) => {
            setCourseMetaData({
              categories: res1.data,
              types: res2.data,
            });
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setErrors([
              ...errors,
              { error: true, message: error?.response?.data?.message },
            ]);
          });
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getStates = async () => {
    setLoading(true);
    client("/api/v1/states", {
      method: "GET",
    })
      .then((res) => {
        setStates(res.data);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getTickets = async () => {
    const { page } = tickets;

    setLoading(true);
    // TODO Change this endpoint to the correct one
    client(`/api/v1/support-ticket/all-support-tickets?page=${page}`, {
      method: "GET",
    })
      .then((res) => {
        setTickets({
          count: res.data.meta.total,
          data: res.data.supportTicket,
          page: res.data.meta.currentPage,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getFAQs = async () => {
    setLoading(true);
    client(`/api/v1/faqs/published?page=${faqs.page}`, {
      method: "GET",
    })
      .then((res) => {
        setFAQs({
          count: res.data.length,
          data: res.data,
        });
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const getAlumni = async () => {
    setLoading(true);
    client(`/api/v1/alumni/all?page=${alumni.page}`, {
      method: "GET",
    })
      .then((res) => {
        setAlumni({
          count: res.data.meta.count,
          data: res.data.trainees,
          page: res.data.meta.currentPage,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors([
          ...errors,
          { error: true, message: error?.response?.data?.message },
        ]);
      });
  };

  const value = {
    loading: loading,
    errorConsumer: () => {
      const availableErrors = errors;
      setErrors([]);
      return availableErrors;
    },
    data: {
      stats: stats,
      adminUsers: adminUsers,
      systemUsers: systemUsers,
      permissions: permissions,
      courseMetaData: courseMetaData,
      courses: courses,
      programs: programs,
      announcements: announcements,
      payments: payments,
      sponsors: sponsors,
      groupApplications: groupApplications,
      trainingCenters: trainingCenters,
      trainees: trainees,
      trainers: trainers,
      states: states,
      tickets: tickets,
      faqs: faqs,
      alumni: alumni,
      courseResults: courseResults,
      programResults: programResults,
      messagingList: messagingList,
    },
    temp: {
      storeMessagingList: (data) => {
        setMessagingList(data);
      },
    },
    loaders: {
      getTrainees: (categories) => {
        getTrainees(categories);
      },
      getTrainers: (categories) => {
        getTrainers(categories);
      },
      getTrainingCenters: () => {
        getTrainingCenters();
      },
      getAdminUsers: (categories) => {
        getAdminUsers(categories);
      },
      getSystemUsers: (categories) => {
        getSystemUsers(categories);
      },
      getPermissions: () => {
        getPermissions();
      },
      getAnnouncements: () => {
        getAnnouncements();
      },
      getStats: () => {
        getStats();
      },
      getSponsors: () => {
        getSponsors();
      },
      getPayments: (status) => {
        getPayments(status);
      },
      getGroupApplications: (companyId) => {
        getGroupApplications(companyId);
      },
      getCourses: () => {
        getCourses();
      },
      getPrograms: () => {
        getPrograms();
      },
      getCourseResults: () => {
        getCourseResults();
      },
      getProgramResults: () => {
        getProgramResults();
      },
      getCourseMetaData: () => {
        getCourseMetaData();
      },
      getStates: () => {
        getStates();
      },
      getTickets: () => {
        getTickets();
      },
      getFAQs: () => {
        getFAQs();
      },
      getAlumni: () => {
        getAlumni();
      },
    },
    pagination: {
      trainees: {
        updatePage: ({ category, newPage = 1 }) => {
          setTrainees({
            ...trainees,
            [category]: {
              ...trainees[category],
              page: newPage,
            },
          });
        },
      },
      trainers: {
        updatePage: ({ category, newPage = 1 }) => {
          setTrainers({
            ...trainers,
            [category]: {
              ...trainers[category],
              page: newPage,
            },
          });
        },
      },
      adminUsers: {
        updatePage: ({ category, newPage = 1 }) => {
          setAdminUsers({
            ...adminUsers,
            [category]: {
              ...adminUsers[category],
              page: newPage,
            },
          });
        },
      },
      systemUsers: {
        updatePage: ({ category, newPage = 1 }) => {
          setSystemUsers({
            ...systemUsers,
            [category]: {
              ...systemUsers[category],
              page: newPage,
            },
          });
        },
      },
      announcements: {
        updatePage: ({ category, newPage = 1 }) => {
          setAnnouncements({
            ...announcements,
            [category]: {
              ...announcements[category],
              page: newPage,
            },
          });
        },
      },
      sponsors: {
        updatePage: ({ newPage = 1 }) => {
          setSponsors({
            ...sponsors,
            page: newPage,
          });
        },
      },
      payments: {
        updatePage: ({ status, newPage = 1 }) => {
          setPayments({
            ...payments,
            [status]: {
              ...[status],
              page: newPage,
            },
          });
        },
      },
      courseResults: {
        updatePage: ({ newPage = 1 }) => {
          setCourseResults({
            ...courseResults,
            page: newPage,
          });
        },
      },
      programResults: {
        updatePage: ({ newPage = 1 }) => {
          setProgramResults({
            ...programResults,
            page: newPage,
          });
        },
      },
      groupApplications: {
        updatePage: ({ newPage = 1 }) => {
          setGroupApplications({
            ...groupApplications,
            page: newPage,
          });
        },
      },
      courses: {
        updatePage: ({ newPage = 1 }) => {
          setCourses({
            ...courses,
            page: newPage,
          });
        },
      },
      tickets: {
        updatePage: ({ newPage = 1 }) => {
          setTickets({
            ...tickets,
            page: newPage,
          });
        },
      },
      faqs: {
        updatePage: ({ newPage = 1 }) => {
          setFAQs({
            ...faqs,
            page: newPage,
          });
        },
      },
      alumni: {
        updatePage: ({ newPage = 1 }) => {
          setAlumni({
            ...alumni,
            page: newPage,
          });
        },
      },
    },
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error(`useData must be used within a DataProvider`);
  }
  return context;
};

export const AsyncData = ({ children }) => {
  const { loading } = useData();
  return loading ? <Spinner size="lg" className="z-1" /> : <>{children}</>;
};

export default DataProvider;
